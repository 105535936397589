/* You can add global styles to this file, and also import other style files */

@import "../node_modules/bootstrap/scss/bootstrap.scss";

html,
body {
  margin: 0;
  font-family: 'SLBSans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  min-width: 768px !important;
  overflow: hidden;
}

/* Track */
mat-card::-webkit-scrollbar-track,
.mat-card::-webkit-scrollbar-track {
  background: #3f4753;
}

/* Handle */
mat-card::-webkit-scrollbar-thumb,
.mat-card::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background: #6c7177;
}

/* Handle on hover */
mat-card::-webkit-scrollbar-thumb:hover,
.mat-card::-webkit-scrollbar-thumb:hover,
.div::-webkit-scrollbar-thumb:hover {
  background: #63676d;
}

.app-content {
  padding: 20px;
}

.contentclass {
  margin-top: 20px;
}

.content-left-margin {
  margin-left: 20px;
}

.content-right-margin {
  margin-right: 20px;
}

.page-content:not(.fullscreen-container) {
  padding: 10px 10px 10px 20px;
  left: 0;
  right: 0;
  position: absolute;
  overflow-y: scroll;

  mat-card,
  .mat-card {
    height: auto;
    overflow-y: auto;
  }
}

.tabcontrolpanel {
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border: none;
  opacity: 0.95;
  background: var(--Greyscale-Grey-02, #131629);
  backdrop-filter: blur(5px);
}

.dls-application-title img {
  height: 30px;
  width: 30px;
  margin-right: 12px;
  pointer-events: none;
  content: url(/assets/vedantalogo.svg);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #1683fb !important;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: #1683fb !important;
  color: white !important;
  background-color: #1683fb !important;
}

.k-grid td.k-selected,
.k-grid .k-table-row.k-selected>td,
.k-grid .k-table-td.k-selected,
.k-grid .k-table-row.k-selected>.k-table-td {
  background: var(--Greyscale-Grey-01, #080912);
}

.k-grid .k-column-title {
  white-space: normal;
}

.k-grid .k-grid-aria-root {
  background-color: #131629 !important;
}

.k-table-thead,
.k-table-header,
.k-table-group-sticky-header {
  background-color: #131629 !important;
}

.k-table-md,
.k-table-md .k-table-td {
  padding-block: 0 !important;
}


.k-table-alt-row {
  background-color: #131629 !important;
}

.k-table,
.k-data-table {
  background-color: #131629 !important;
}


.k-grid tbody tr td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.k-grid-header,
.k-grid-header-wrap,
.k-grouping-header,
.k-grid,
.k-grid td,
.k-grid .k-table-td,
.k-grid-footer,
.k-grid-footer-wrap,
.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked,
.k-filter-row>.k-table-th,
.k-filter-row>td,
.k-filter-row>.k-table-td {
  border-bottom: solid 1px #505575;
}

.k-pager {
  background-color: #131629 !important;
  border-top: solid 1px #505575;
}

.k-grid .k-grid-header .k-table-th {
  vertical-align: top;
}

.k-input-solid {
  border-color: transparent;
  background-color: transparent;
}

.k-grid-content {
  background-color: transparent;
}

.headerLabel {
  line-height: 24px;
  display: inline-block;
}

.header-right-tools {
  position: absolute;
  right: 0;
  margin-right: 20px;
  padding-bottom: 20px;
}

.space-div {
  width: 10px;
  display: inline-block;
}

mat-label {
  padding-left: 1px;
}

.add-button {
  border-radius: 40px;
  border: 1px solid var(--Alert-Colors-Dark-DT_Alert-Success, #87EA2D);
  background: var(--Greyscale-Grey-01, #080912);
}

.delete-button {
  border-radius: 40px;
  border: 1px solid var(--Theme-Colors-Warn-Dark-DT_Base-Red, #FF6B6B);
  background: var(--Greyscale-Grey-01, #080912);
  margin-left: 5px;
}

.dls-message-box-outer {
  width: 90% !important;
}

.dls-message-box-inner {
  width: 100%;
}

.highcharts-no-data {
  color: #EBECF2 !important;
  font-size: 18px;
}

.update-range-dialog {
  overflow: auto !important;
}

.dls-navigation-toolbar-suffix .dls-icon-button {
  display: none !important;
}

.dls-navigation-toolbar-suffix {
  display: none !important;
}

#searchboxlist-panel {
  padding: 0 0 8px 0 !important;
}

.fix-header-border {
  border-left: 1px solid #5E5E5E !important;
}

.float-header-filter {
  background-color: #22263d;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.highcharts-button-hover {
  background-color: #080912 !important;
}

.dls-navigation-framework-content {
  overflow-y: auto !important;
}

.dls-framework-content {
  overflow-y: auto !important;
}

.mat-mdc-card-content {
  min-height: 100%;
  position: relative;
}

.mat-mdc-card-content>div,
.mat-mdc-card-content>div kendo-pdfviewer,
div.pdf-viewer.fullscreen kendo-pdfviewer {
  min-height: 100%;
}

kendo-grid-toolbar>div.row {
  width: 100%;
}

.dls-side-menu .mat-mdc-list-item .mat-icon {
  width: 24px;
  height: 24px;
}

.fullscreen-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: #22263d;
  overflow: auto;
}

.fullscreen-container kendo-pdfviewer {
  height: 100%;
}

html .mat-icon,
html .dls-secondary-button.dls-medium .mat-icon {
  line-height: 0;
}

.hide-row {
  display: none !important;
}

button.pdf-fullscreen {
  position: absolute;
  top: 9px;
  left: 325px;
  z-index: 3;
  cursor: pointer;
}

/** Image Preview styles start **/

.image-preview-container {
  .div.no-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  div.image-container {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    cursor: grab;
  }

  .image-container.dragging {
    cursor: grabbing;
  }

  .image-viewer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #4c4d53;
  }

  .image-viewer img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    transform: scale(1);
  }

  .controls {
    padding: 10px;
    top: 0;
    width: 100%;
    background-color: #080912;
    z-index: 3;
    position: relative;
  }

  .controls>div.controls-group {
    margin-right: 10px;
    display: inline-block;
  }

  .controls>div.controls-group>button,
  .controls>button {
    background-color: #202129;
    color: #fff;
    cursor: pointer;
    font-size: 22px;
    min-width: 30px;
    min-height: 30px;
  }

  .controls>div.controls-group>button:hover,
  .controls>button:hover {
    color: #4c4d53;
  }

  .controls>div.controls-group>button:disabled,
  .controls>button:disabled {
    background-color: #4c4d53;
    color: #ccc;
  }

  button.custom-btn {
    top: 7px;
    position: relative;
    border: none;
  }

  button[expand] {
    margin-left: 5px;
    margin-right: 5px;
  }

  button.custom-btn mat-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 0;
  }

  .image-container canvas {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    cursor: crosshair;
    z-index: 2;
  }

  .image-container canvas:not(.drawing):not(.writting):active {
    cursor: grabbing;
  }

  .image-container canvas.drawing {
    cursor: crosshair;
  }

  .image-container canvas.writting {
    cursor: text;
  }

  .image-container img {
    position: relative;
    z-index: 1;
  }

  div.color-selector {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    width: 30px;
    height: 22px;

    &>input[type="color"] {
      width: 30px;
      height: 30px;
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
}

/** Image Preview styles: end **/

.las-viewer-chart .highcharts-reset-zoom {
  display: none;
}

slb-framework-content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(100% - 10px);
}

.searchBox {
  background-color: #22263d;
  border: none;
  padding: 10px;
  color: #fff;
}

.searchBox::placeholder {
  color: #fff;
  opacity: 0.6;
}

html .mat-mdc-card-header .mat-mdc-card-title,
html .mat-mdc-card-title {
  display: inline-block;
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 2560px) {

  html,
  body {
    font-size: 28px !important;
    line-height: 48px !important;
  }

  .app-content {
    padding: 40px;
  }

  .contentclass {
    margin-top: 40px;
  }

  .content-left-margin {
    margin-left: 40px;
  }

  .content-right-margin {
    margin-right: 40px;
  }

  .page-content:not(.fullscreen-container) {
    margin: 20px 20px 20px 40px;
  }

  .tabcontrolpanel {
    padding: 24px 40px;
    gap: 32px;
    backdrop-filter: blur(10);
  }

  .dls-application-title img {
    height: 60px !important;
    width: 60px !important;
    margin-right: 24px !important;
  }

  .k-grid-header,
  .k-grid-header-wrap,
  .k-grouping-header,
  .k-grid,
  .k-grid td,
  .k-grid .k-table-td,
  .k-grid-footer,
  .k-grid-footer-wrap,
  .k-grid-content-locked,
  .k-grid-footer-locked,
  .k-grid-header-locked,
  .k-filter-row>.k-table-th,
  .k-filter-row>td,
  .k-filter-row>.k-table-td {
    border-bottom: solid 2px #505575;
  }

  .k-pager {
    border-top: solid 2px #505575;
  }

  .headerLabel {
    line-height: 48px;
  }

  .header-right-tools {
    margin-right: 40px;
    padding-bottom: 40px;
  }

  .space-div {
    width: 20px;
  }

  mat-label {
    padding-left: 2px;
  }

  .add-button {
    border: 2px solid var(--Alert-Colors-Dark-DT_Alert-Success, #87EA2D);
  }

  .delete-button {
    border: 2px solid var(--Theme-Colors-Warn-Dark-DT_Base-Red, #FF6B6B);
    margin-left: 10px;
  }

  .highcharts-no-data {
    font-size: 36px;
  }

  #searchboxlist-panel {
    padding: 0 0 16px 0 !important;
  }

  .fix-header-border {
    border-left: 2px solid #5E5E5E !important;
  }

  .dls-side-menu .mat-mdc-list-item .mat-icon {
    width: 48px;
    height: 48px;
  }

  button.pdf-fullscreen {
    top: 18px;
    left: 650px;
  }

  .mat-mdc-card-header .mat-mdc-card-title,
  .mat-mdc-card-title {
    font-size: 36px !important;
    line-height: 32px;
  }

  /** Image Preview styles start **/

  .image-preview-container {
    .controls {
      padding: 20px;
    }

    .controls>div.controls-group {
      margin-right: 20px;
    }

    .controls>div.controls-group>button,
    .controls>button {
      font-size: 44px;
      min-width: 60px;
      min-height: 60px;
    }

    button.custom-btn {
      top: 14px;
    }

    button[expand] {
      margin-left: 10px;
      margin-right: 10px;
    }

    div.color-selector {
      margin-left: 10px;
      width: 60px;
      height: 44px;

      &>input[type="color"] {
        width: 60px;
        height: 60px;
      }
    }
  }

  /** Image Preview styles: end **/

  html .dls-sidenav .dls-side-menu .mat-mdc-list-item .mdc-list-item__content .mdc-list-item__primary-text,
  html .dls-sidenav .dls-side-menu .mat-mdc-list-item .mdc-list-item__content .mdc-list-item__secondary-text {
    font-size: 32px;
    line-height: 3;
  }

  html .dls-sidenav {
    font-size: 28px;
    line-height: 32px;
  }

  .dls-sidenav {
    min-width: 80px !important;
    width: 500px !important;
    padding: 40px 30px 40px 40px !important;
  }

  .dls-sidenav-content:has(button.close) {
    margin-left: 500px !important;
  }

  .dls-sidenav-container.mat-drawer-container.mat-sidenav-container .slb-logo {
    width: 110px !important;
  }

  html .mat-icon {
    width: 40px;
    height: 40px;
  }

  html .dls-sidenav-panel .dls-sidenav-toggle-button {
    width: 40px;
    height: 40px;
    left: -56px;
  }

  html .dls-sidenav .dls-side-menu .mat-mdc-list-item {
    font-size: 28px;
  }

  html .mat-mdc-list-base .mat-mdc-list-item {
    height: 68px;
  }

  .dls-navigation-toolbar.mat-toolbar-single-row {
    height: 104px !important;
  }

  html .dls-branding,
  html .dls-application-title,
  html .dls-application-title-link,
  html .dls-application-title-text {
    line-height: 48px;
    font-size: 32px;
  }

  .dls-user-identity.dls-small,
  .dls-user-identity.dls-small .dls-user-identity-wrapper {
    width: 48px !important;
    height: 48px !important;
  }

  .dls-framework-toolbar {
    height: 104px !important;
  }

  .dls-navigation-toolbar .dls-logout {
    margin-left: 28px !important;
  }

  .dls-logout {
    width: 72px !important;
    height: 72px !important;
  }

  .dls-logout-user-identity {
    width: 72px !important;
    height: 72px !important;
    padding: 6px !important;
  }

  html .dls-page-header-container {
    padding: 20px 40px;
  }

  html .dls-sidenav-content.mat-drawer-content.mat-sidenav-content {
    font-size: 28px;
    line-height: 32px;
  }

  .dls-page-header-row {
    min-height: 72px !important;
  }

  html .dls-page-header-container .dls-page-header-row .dls-page-title {
    line-height: 64px;
    font-size: 48px;
  }

  .dls-page-title {
    min-height: 120px !important;
  }

  html .dls-icon-button.dls-medium {
    line-height: 72px;
    height: 72px;
    width: 72px;
    font-size: 24px;
  }

  .dls-page-title .dls-icon-button.dls-medium svg {
    width: 40px !important;
    height: 40px !important;
  }

  .dls-page-title-text {
    font-size: 48px !important;
    line-height: 64px !important;
    letter-spacing: 1.44px !important;
  }

  html .mat-mdc-tab.mdc-tab,
  html .mat-mdc-tab-link.mdc-tab {
    font-size: 32px;
  }

  html .mat-mdc-form-field {
    padding-top: 32px;
    font-size: 32px;
    line-height: 40px;
  }

  html .mat-mdc-form-field .mdc-text-field--outlined {
    padding: 0 24px;
    border-radius: 16px;
  }

  html .mat-mdc-form-field .mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: 16px 0 16px;
  }

  .mat-mdc-form-field-infix {
    width: 360px !important;
  }

  html .mat-mdc-select {
    padding: 4px 0;
  }

  html .mat-mdc-select-trigger {
    height: 32px;
  }

  html .mat-mdc-select-value {
    font-size: 32px;
    line-height: 40px;
  }

  .mat-mdc-select-arrow {
    width: 20px;
    height: 10px;
  }

  .mat-mdc-select-arrow svg {
    width: 48px;
    height: 48px;
  }

  html .cdk-overlay-pane {
    min-width: 200px;
  }

  div.mat-mdc-select-panel {
    padding: 16px 0;
    max-height: 550px !important;
  }

  html .mat-mdc-select-panel .mat-mdc-optgroup-label,
  html .mat-mdc-select-panel .mat-mdc-option {
    height: 84px;
    padding: 0 24px;
  }

  html .mat-mdc-option .mdc-list-item__primary-text {
    font-size: 32px;
    line-height: 1.30;
    letter-spacing: .96;
  }

  .mat-select-search-inner[_ngcontent-ng-c3814470072] {
    padding: 29px !important;
  }

  .searchBox[_ngcontent-ng-c3814470072] {
    height: 60px !important;
  }

  html .dls-button.dls-medium,
  html .dls-alternative-button.dls-medium,
  html .dls-secondary-button.dls-medium,
  html .dls-secondary-alternative-button.dls-medium,
  html .dls-tertiary-button.dls-medium,
  html .dls-search-button.dls-medium {
    font-size: 24px;
    height: 72px;
    border-radius: 72px;
  }

  html .dls-button.dls-medium:not(.dls-round-button),
  html .dls-alternative-button.dls-medium:not(.dls-round-button),
  html .dls-secondary-button.dls-medium:not(.dls-round-button),
  html .dls-secondary-alternative-button.dls-medium:not(.dls-round-button),
  html .dls-tertiary-button.dls-medium:not(.dls-round-button),
  html .dls-search-button.dls-medium:not(.dls-round-button) {
    padding: 0 32px;
  }

  html .mat-mdc-card {
    padding: 40px;
  }

  html .mat-button-toggle-group:not(.mat-button-toggle-vertical) {
    padding: 8px;
    border-radius: 40px;
  }

  .submenu {
    top: 30px !important;
    right: 30px !important;
  }

  html .mat-button-toggle:not(:last-child) {
    margin-right: 8px;
  }

  html .mat-button-toggle .mat-button-toggle-label-content {
    line-height: 32px;
    font-size: 28px;
    letter-spacing: .06rem;
    height: 56px;
    border-radius: 28px;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0 24px;
  }

  app-deviation-summary {
    .interval-label {
      font-size: 36px !important;
      padding: 4px 20px !important;
    }

    .interval-input {
      width: 200px !important;
      font-size: 32px !important;
      padding: 4px 24px !important;
      border-radius: 16px !important;
      line-height: 48px !important;
    }

    kendo-grid h4 {
      font-size: 36px !important;
      line-height: 48px !important;
    }
  }

  .k-toolbar-md {
    padding-block: 16px;
    padding-inline: 16px;
  }

  .k-grid .k-grid-md .k-table-th>.k-cell-inner>.k-link,
  .k-grid-md .k-table-th>.k-cell-inner>.k-link {
    padding-block: 16px;
    padding-inline: 24px;
  }

  .k-grid .k-grid-md .k-table-th>.k-cell-inner,
  .k-grid-md .k-table-th>.k-cell-inner {
    padding-block: -16px;
    padding-inline: -24px;
  }

  .k-grid .k-grid-md .k-table-th,
  .k-grid-md .k-table-th {
    padding-block: 16px;
    padding-inline: 24px;
  }

  .k-table-md {
    font-size: 28px;
  }

  .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    padding-block: 8px;
    padding-inline: 16px;
  }

  .k-input-md,
  .k-picker-md {
    font-size: 28px !important;
  }

  html .mat-mdc-card-header .mat-mdc-card-title,
  html .mat-mdc-card-title {
    line-height: 32px;
    letter-spacing: .06rem;
  }

  html .mat-mdc-card-header {
    margin-bottom: 40px;
  }

  .dls-date-picker-has-range {
    min-width: 580px !important;
    width: 580px !important;
  }

  html .dls-icon-button.dls-medium svg,
  html .dls-icon-button.dls-medium .mat-icon {
    width: 32px !important;
    height: 32px !important;
  }

  html .dls-secondary-button:not([disabled]):not(:disabled):focus:not(.dls-round-button) {
    padding: 0 30px;
  }

  html .dls-button.dls-medium svg,
  html .dls-button.dls-medium .mat-icon,
  html .dls-alternative-button.dls-medium svg,
  html .dls-alternative-button.dls-medium .mat-icon,
  html .dls-secondary-button.dls-medium svg,
  html .dls-secondary-button.dls-medium .mat-icon,
  html .dls-secondary-alternative-button.dls-medium svg,
  html .dls-secondary-alternative-button.dls-medium .mat-icon,
  html .dls-tertiary-button.dls-medium svg,
  html .dls-tertiary-button.dls-medium .mat-icon,
  html .dls-search-button.dls-medium svg,
  html .dls-search-button.dls-medium .mat-icon {
    width: 32px;
    height: 32px;
  }

  .k-checkbox-md {
    width: 32px;
    height: 32px;
  }

  .k-button-md.k-icon-button .k-button-icon {
    min-width: calc(28px * 1.4285714286);
    min-height: calc(28px * 1.4285714286)
  }

  .k-button-md.k-icon-button .k-button-icon.k-svg-icon>svg,
  .k-button-md.k-icon-button .k-button-icon.k-icon-md>svg {
    width: 32px;
    height: 32px;
  }

  .k-pager-info.k-label {
    font-size: 28px;
  }

  .searchBox {
    padding: 20px;
  }

  .loading-text {
    font-size: 32px;
  }
}

@media screen and (max-width: 1280px) {

  html,
  body {
    font-size: 10.5px !important;
    line-height: 18px !important;
  }

  .app-content {
    padding: 15px;
  }

  .contentclass {
    margin-top: 15px;
  }

  .content-left-margin {
    margin-left: 15px;
  }

  .content-right-margin {
    margin-right: 15px;
  }

  .page-content:not(.fullscreen-container) {
    margin: 7.5px 7.5px 7.5px 15px;
  }

  .tabcontrolpanel {
    padding: 9px 15px;
    gap: 12px;
  }

  .dls-application-title img {
    height: 22.5px !important;
    width: 22.5px !important;
    margin-right: 9px !important;
  }

  .k-grid-header,
  .k-grid-header-wrap,
  .k-grouping-header,
  .k-grid,
  .k-grid td,
  .k-grid .k-table-td,
  .k-grid-footer,
  .k-grid-footer-wrap,
  .k-grid-content-locked,
  .k-grid-footer-locked,
  .k-grid-header-locked,
  .k-filter-row>.k-table-th,
  .k-filter-row>td,
  .k-filter-row>.k-table-td {
    border-bottom: solid 1px #505575;
  }

  .k-pager {
    border-top: solid 1px #505575;
  }

  .headerLabel {
    line-height: 18px;
  }

  .header-right-tools {
    margin-right: 15px;
    padding-bottom: 15px;
  }

  .space-div {
    width: 7.5px;
  }

  mat-label {
    padding-left: 1px;
  }

  .add-button {
    border: 1px solid var(--Alert-Colors-Dark-DT_Alert-Success, #87EA2D);
  }

  .delete-button {
    border: 1px solid var(--Theme-Colors-Warn-Dark-DT_Base-Red, #FF6B6B);
    margin-left: 3.75px;
  }

  .highcharts-no-data {
    font-size: 13.5px;
  }

  #searchboxlist-panel {
    padding: 0 0 6px 0 !important;
  }

  .fix-header-border {
    border-left: 1px solid #5E5E5E !important;
  }

  .dls-side-menu .mat-mdc-list-item .mat-icon {
    width: 18px;
    height: 18px;
  }

  button.pdf-fullscreen {
    top: 6.75px;
    left: 243.75px;
  }

  .mat-mdc-card-header .mat-mdc-card-title,
  .mat-mdc-card-title {
    font-size: 13.5px !important;
    line-height: 12px;
  }

  /** Image Preview styles start **/

  .image-preview-container {
    .controls {
      padding: 7.5px;
    }

    .controls>div.controls-group {
      margin-right: 7.5px;
    }

    .controls>div.controls-group>button,
    .controls>button {
      font-size: 16.5px;
      min-width: 22.5px;
      min-height: 22.5px;
    }

    button.custom-btn {
      top: 5.25px;
    }

    button[expand] {
      margin-left: 3.75px;
      margin-right: 3.75px;
    }

    div.color-selector {
      margin-left: 3.75px;
      width: 22.5px;
      height: 16.5px;

      &>input[type="color"] {
        width: 22.5px;
        height: 22.5px;
      }
    }
  }

  /** Image Preview styles: end **/

  html .dls-sidenav .dls-side-menu .mat-mdc-list-item .mdc-list-item__content .mdc-list-item__primary-text,
  html .dls-sidenav .dls-side-menu .mat-mdc-list-item .mdc-list-item__content .mdc-list-item__secondary-text {
    font-size: 12px;
    line-height: 1.125;
  }

  html .dls-sidenav {
    font-size: 10.5px;
    line-height: 12px;
  }

  .dls-sidenav {
    min-width: 30px !important;
    width: 187.5px !important;
    padding: 15px 11.25px 15px 15px !important;
  }

  .dls-sidenav-content:has(button.close) {
    margin-left: 187.5px !important;
  }

  .dls-sidenav-container.mat-drawer-container.mat-sidenav-container .slb-logo {
    width: 41.25px !important;
  }

  html .mat-icon {
    width: 15px;
    height: 15px;
  }

  html .dls-sidenav-panel .dls-sidenav-toggle-button {
    width: 15px;
    height: 15px;
    left: -21px;
  }

  html .dls-sidenav .dls-side-menu .mat-mdc-list-item {
    font-size: 10.5px;
  }

  html .mat-mdc-list-base .mat-mdc-list-item {
    height: 25.5px;
  }

  .dls-navigation-toolbar.mat-toolbar-single-row {
    height: 39px !important;
  }

  html .dls-branding,
  html .dls-application-title,
  html .dls-application-title-link,
  html .dls-application-title-text {
    line-height: 18px;
    font-size: 12px;
  }

  .dls-user-identity.dls-small,
  .dls-user-identity.dls-small .dls-user-identity-wrapper {
    width: 18px !important;
    height: 18px !important;
  }

  .dls-framework-toolbar {
    height: 39px !important;
  }

  .dls-navigation-toolbar .dls-logout {
    margin-left: 10.5px !important;
  }

  .dls-logout {
    width: 27px !important;
    height: 27px !important;
  }

  .dls-logout-user-identity {
    width: 27px !important;
    height: 27px !important;
    padding: 2.25px !important;
  }

  html .dls-page-header-container {
    padding: 7.5px 15px;
  }

  html .dls-sidenav-content.mat-drawer-content.mat-sidenav-content {
    font-size: 10.5px;
    line-height: 12px;
  }

  .dls-page-header-row {
    min-height: 27px !important;
  }

  html .dls-page-header-container .dls-page-header-row .dls-page-title {
    line-height: 24px;
    font-size: 18px;
  }

  .dls-page-title {
    min-height: 45px !important;
  }

  html .dls-icon-button.dls-medium {
    line-height: 27px;
    height: 27px;
    width: 27px;
    font-size: 9px;
  }

  .dls-page-title .dls-icon-button.dls-medium svg {
    width: 15px !important;
    height: 15px !important;
  }

  .dls-page-title-text {
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: 0.54px !important;
  }

  html .mat-mdc-tab.mdc-tab,
  html .mat-mdc-tab-link.mdc-tab {
    font-size: 12px;
  }

  html .mat-mdc-form-field {
    padding-top: 12px;
    font-size: 12px;
    line-height: 15px;
  }

  html .mat-mdc-form-field .mdc-text-field--outlined {
    padding: 0 9px;
    border-radius: 6px;
  }

  html .mat-mdc-form-field .mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: 6px 0 6px;
  }

  .mat-mdc-form-field-infix {
    width: 135px !important;
  }

  html .mat-mdc-select {
    padding: 1.5px 0;
  }

  html .mat-mdc-select-trigger {
    height: 12px;
  }

  html .mat-mdc-select-value {
    font-size: 12px;
    line-height: 15px;
  }

  .mat-mdc-select-arrow {
    width: 7.5px;
    height: 3.75px;
  }

  .mat-mdc-select-arrow svg {
    width: 18px;
    height: 18px;
  }

  html .cdk-overlay-pane {
    min-width: 75px;
  }

  div.mat-mdc-select-panel {
    padding: 6px 0;
    max-height: 206.25px !important;
  }

  html .mat-mdc-select-panel .mat-mdc-optgroup-label,
  html .mat-mdc-select-panel .mat-mdc-option {
    height: 31.5px;
    padding: 0 9px;
  }

  html .mat-mdc-option .mdc-list-item__primary-text {
    font-size: 12px;
    line-height: 1.48;
    letter-spacing: .36px;
  }

  .mat-select-search-inner[_ngcontent-ng-c3814470072] {
    padding: 10.8px !important;
  }

  .searchBox[_ngcontent-ng-c3814470072] {
    height: 22.5px !important;
  }

  html .dls-button.dls-medium,
  html .dls-alternative-button.dls-medium,
  html .dls-secondary-button.dls-medium,
  html .dls-secondary-alternative-button.dls-medium,
  html .dls-tertiary-button.dls-medium,
  html .dls-search-button.dls-medium {
    font-size: 9px;
    height: 27px;
    border-radius: 27px;
  }

  html .dls-button.dls-medium:not(.dls-round-button),
  html .dls-alternative-button.dls-medium:not(.dls-round-button),
  html .dls-secondary-button.dls-medium:not(.dls-round-button),
  html .dls-secondary-alternative-button.dls-medium:not(.dls-round-button),
  html .dls-tertiary-button.dls-medium:not(.dls-round-button),
  html .dls-search-button.dls-medium:not(.dls-round-button) {
    padding: 0 12px;
  }

  html .mat-mdc-card {
    padding: 15px;
  }

  html .mat-button-toggle-group:not(.mat-button-toggle-vertical) {
    padding: 3px;
    border-radius: 15px;
  }

  .submenu {
    top: 11.25px !important;
    right: 11.25px !important;
  }

  html .mat-button-toggle:not(:last-child) {
    margin-right: 3px;
  }

  html .mat-button-toggle .mat-button-toggle-label-content {
    line-height: 12px;
    font-size: 10.5px;
    letter-spacing: .022rem;
    height: 21px;
    border-radius: 10.5px;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0 9px;
  }

  app-deviation-summary {
    .interval-label {
      font-size: 13.5px !important;
      padding: 1.5px 7.5px !important;
    }

    .interval-input {
      width: 75px !important;
      font-size: 12px !important;
      padding: 1.5px 9px !important;
      border-radius: 6px !important;
      line-height: 18px !important;
    }

    kendo-grid h4 {
      font-size: 13.5px !important;
      line-height: 18px !important;
    }
  }

  .k-toolbar-md {
    padding-block: 6px;
    padding-inline: 6px;
  }

  .k-grid .k-grid-md .k-table-th>.k-cell-inner>.k-link,
  .k-grid-md .k-table-th>.k-cell-inner>.k-link {
    padding-block: 6px;
    padding-inline: 9px;
  }

  .k-grid .k-grid-md .k-table-th>.k-cell-inner,
  .k-grid-md .k-table-th>.k-cell-inner {
    padding-block: -6px;
    padding-inline: -9px;
  }

  .k-grid .k-grid-md .k-table-th,
  .k-grid-md .k-table-th {
    padding-block: 6px;
    padding-inline: 9px;
  }

  .k-table-md {
    font-size: 10.5px;
  }

  .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    padding-block: 3px;
    padding-inline: 6px;
  }

  .k-input-md,
  .k-picker-md {
    font-size: 10.5px !important;
  }

  html .mat-mdc-card-header .mat-mdc-card-title,
  html .mat-mdc-card-title {
    line-height: 12px;
    letter-spacing: .0225rem;
  }

  html .mat-mdc-card-header {
    margin-bottom: 15px;
  }

  .dls-date-picker-has-range {
    min-width: 217.5 !important;
    width: 217.5 !important;
  }

  html .dls-icon-button.dls-medium svg,
  html .dls-icon-button.dls-medium .mat-icon {
    width: 12px !important;
    height: 12px !important;
  }

  html .dls-secondary-button:not([disabled]):not(:disabled):focus:not(.dls-round-button) {
    padding: 0 11.25px;
  }

  html .dls-button.dls-medium svg,
  html .dls-button.dls-medium .mat-icon,
  html .dls-alternative-button.dls-medium svg,
  html .dls-alternative-button.dls-medium .mat-icon,
  html .dls-secondary-button.dls-medium svg,
  html .dls-secondary-button.dls-medium .mat-icon,
  html .dls-secondary-alternative-button.dls-medium svg,
  html .dls-secondary-alternative-button.dls-medium .mat-icon,
  html .dls-tertiary-button.dls-medium svg,
  html .dls-tertiary-button.dls-medium .mat-icon,
  html .dls-search-button.dls-medium svg,
  html .dls-search-button.dls-medium .mat-icon {
    width: 12px;
    height: 12px;
  }

  .k-checkbox-md {
    width: 12px;
    height: 12px;
  }

  .k-button-md.k-icon-button .k-button-icon {
    min-width: calc(10.5px * 1.4285714286);
    min-height: calc(10.5px * 1.4285714286)
  }

  .k-button-md.k-icon-button .k-button-icon.k-svg-icon>svg,
  .k-button-md.k-icon-button .k-button-icon.k-icon-md>svg {
    width: 12px;
    height: 12px;
  }

  .k-pager-info.k-label {
    font-size: 10.5px;
  }

  .dls-side-menu .mat-mdc-list-item .mdc-list-item__content {
    padding-left: 3px;
  }

  .dls-side-menu .mat-mdc-list-item {
    min-height: 15px !important;
  }

  .k-button-md,
  .k-pager {
    font-size: 10.5px !important;
    line-height: 1.07 !important;
  }

  .k-button-md {
    padding-block: 3px;
    padding-inline: 6px;
  }

  .k-pager-md .k-pager-numbers-wrap .k-button {
    min-width: calc(1.07em + 6px + 1.5px);
  }

  .k-button-md.k-icon-button {
    padding: 3px !important;
  }

  .k-pager-numbers {
    padding-top: 3px;
  }

  html .dls-button-base .mat-icon {
    line-height: 7.5px;
  }

  .searchBox {
    padding: 7.5px;
  }

  .loading-text {
    font-size: 12px;
  }
}

.kendotoolbarpanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: auto;
}

.dots::after {
  content: '';
  display: inline-block;
  width: 1em;
  text-align: left;
  animation: dots 1.5s steps(4, end) infinite;
}

.loading-text {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

@keyframes dots {

  0%,
  20% {
    content: '';
  }

  40% {
    content: '.';
  }

  60% {
    content: '..';
  }

  80%,
  100% {
    content: '...';
  }
}
.dateFilterTextBox {
  background-color: transparent;
  color: white;
  border: none;
}
